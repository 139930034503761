body {
  margin: 0;
  text-align: center;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 400;
  background: rgb(25,25,25);
  -webkit-font-smoothing: antialiased;
  background: rgb(25,25,25);

}
.client{
  width: 106px;
}
.info{
  margin-bottom: 20px;
  margin-top: 40px;
  font-size: 14px;
  width: 340px;
}

.error{
  padding: 8px;
  font-size: 14px;
  color: #FF0000;
}

p{
  font-size: 12px;
}
.App-logo {
  width: 140px;
}

.link{
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #97D700;
  padding: 20px;
}
label{
  text-transform: uppercase;
  color: #97D700;
}
input{
  font-size: 16px;
  line-height: 1.42857143;
  color: #ccc;
  width: 285px;
  background-color: #333;
  display: block;
  height: 40px;
  text-align: center;
  padding: 6px 12px;
  border: 0 solid #ccc;
  border-bottom: 1px solid #97D700;
  margin-top: 8px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition:border-color .15s ease-in-out, box-shadow .15s ease-in-out
}
.App-header {
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(0deg, rgba(151,215,0,0.2) 0%, rgba(25,25,25,1) 20%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.btn {
  padding: 6px 12px;
  margin-top: 18px;
  margin-bottom: 16px;
  border-radius: 42px;
  font-size: 14px;
  font-weight: bolder;
  color: #191919;
  text-align: center;
  height: 42px;
  text-transform: uppercase;
  width: 186px;
  cursor: pointer;
  white-space: nowrap;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  background-color: #97D700;
}
.advise{
  color: white;
  width: 180px;
}
